import type { IconsType } from "../../../interfaces/iconInterfaces/iconInterfaces";

export const AudienceActionsName = ["Edit", "Stats", "Export all transactions", "Export", "Reconcile", "Copy", "Add Audience", "Delete"] as const;
export type AudienceActionsNameType = (typeof AudienceActionsName)[number];

export const AudienceActions = ["edit", "stats", "exportAllTransactions", "export", "reconcile", "copy", "addAudience", "delete"] as const;
export type AudienceActionsType = (typeof AudienceActions)[number];

export type AudienceActionItem = {
  name: AudienceActionsNameType;
  icon?: IconsType;
  id: AudienceActionsType;
  iconClass?: string;
  disabled?: boolean
}

export const returnActionItems = (type: "sample" | "audience" | "batch") => {
  const sampleItems: AudienceActionItem[] = [
    {
      name: "Add Audience",
      icon: "add",
      id: "addAudience"
    },
    {
      name: "Edit",
      icon: "edit",
      id: "edit"
    },
    {
      name: "Copy",
      icon: "copy",
      id: "copy"
    },
    {
      name: "Stats",
      icon: "gk-stats",
      id: "stats"
    },
    {
      name: "Export all transactions",
      icon: "nav-expand",
      id: "exportAllTransactions"
    },
    {
      name: "Export",
      icon: "export",
      id: "export"
    },
    {
      name: "Reconcile",
      icon: "transaction-complete",
      id: "reconcile"
    },
    {
      name: "Delete",
      icon: "delete",
      id: "delete"
    }
  ]

  const audienceItems: AudienceActionItem[] = [
    {
      name: "Edit",
      icon: "edit",
      id: "edit"
    },
    {
      name: "Copy",
      icon: "copy",
      id: "copy"
    },
    {
      name: "Stats",
      icon: "gk-stats",
      id: "stats"
    },
    {
      name: "Export all transactions",
      icon: "nav-expand",
      id: "exportAllTransactions"
    },
    {
      name: "Export",
      icon: "export",
      id: "export"
    },
    {
      name: "Stats",
      icon: "gk-stats",
      id: "stats"
    },
    {
      name: "Reconcile",
      icon: "transaction-complete",
      id: "reconcile"
    },
    {
      name: "Delete",
      icon: "delete",
      id: "delete"
    }
  ];

  const batchItems: AudienceActionItem[] = [
    {
      name: "Edit",
      icon: "edit",
      id: "edit"
    },
    {
      name: "Copy",
      icon: "copy",
      id: "copy"
    },
    {
      name: "Export",
      icon: "export",
      id: "export"
    },
    {
      name: "Stats",
      icon: "gk-stats",
      id: "stats"
    },
    {
      name: "Delete",
      icon: "delete",
      id: "delete"
    }
  ]

  const items = { "sample": sampleItems, "audience": audienceItems, "batch": batchItems }

  return items[type]
} 