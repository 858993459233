import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { Input } from "@progress/kendo-react-inputs";
import { type FormEvent, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchDeleteJson } from "../../../services/services";
import type { AudiencesType, Batch, Sample } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { setAudiences } from "../../../store/reducers/audiencesReducer/audiencesReducer";

type Props = {
  type: "sample" | "audience" | "batch";
  setShowDeleteModal: (show: boolean) => void;
  sample: Sample;
  audience?: AudiencesType;
  batch?: Batch
}

export const DeleteModal = ({ type, setShowDeleteModal, sample, audience, batch }: Props) => {
  const audiencesData = useSelector((state: RootState) => state.audiencesDataReducer);
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [errorMessage, setErrorMessage] = useState("")
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const deleteUrl = { "sample": `au/a/projects/${sample.projectId}/samples/${sample.id}`, "audience": `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}`, "batch": `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/batches/${batch?.id}` }

  const removeItemFromList = () => {
    switch (type) {
      case "sample": {
        const updatedSamples = audiencesData.samples.filter((item) => item.id !== sample.id);
        dispatch(setAudiences({ ...audiencesData, samples: updatedSamples }));
        break;
      }
      case "audience":
        if (audience?.id) {
          const updatedAud = [{ ...sample, audiences: sample.audiences.filter((item) => item.id !== audience.id) }]
          const updatedSamples = audiencesData.samples.map(obj => updatedAud.find(o => o.id === obj.id) || obj)

          dispatch(setAudiences({ ...audiencesData, samples: updatedSamples }));
        }
        break;
      case "batch":
        if (audience && batch?.id) {
          const updatedAud = [{ ...audience, batches: audience.batches.filter((item) => item.id !== batch.id) }];
          const updatedSample = [{ ...sample, audiences: sample.audiences.map(obj => updatedAud.find(o => o.id === obj.id) || obj) }];
          const updatedSamples = audiencesData.samples.map(obj => updatedSample.find(o => o.id === obj.id) || obj)

          dispatch(setAudiences({ ...audiencesData, samples: updatedSamples }));
        }
        break;
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    fetchDeleteJson(deleteUrl[type], token, false, true)
      .then((res: TODO) => {
        setIsLoading(false);
        if (res && res.status === 204) {
          removeItemFromList();
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The ${type} has been deleted successfully` } })
          setShowDeleteModal(false);
        } else if (res) {
          setErrorMessage(res.message ? res.message : res);
        }
      })
  }


  return (
    <Dialog width={500} height={400} title={type === "sample" ? `Delete ${sample.name}` : type === "audience" ? `Delete ${audience?.displayName}` : `Delete ${batch?.displayName}`} onClose={() => setShowDeleteModal(false)}>
      <form className="d-flex flex-column justify-content-between h-100 gap-md px-2" onSubmit={(e) => handleSubmit(e)}>
        <div className="d-flex flex-column gap-md py-4">
          <p>
            Confirm the deletion of the {type} by typing the {type} <strong>name</strong> in the input field
          </p>
          <Input
            required
            onChange={e => setName(typeof e.target.value === "string" ? e.target.value : "")}
            value={name}
            placeholder={`Enter ${type} name`}
            name="name"
            className="w-100" />
          {
            errorMessage &&
            <div className="mt-4">
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage} />
            </div>
          }
        </div>

        <DialogActionsBar>
          <button type='button' className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
          <button
            type='submit'
            className="btn btn-primary"
            disabled={isLoading}>
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm mr-2" />
            }
            Delete
          </button>
        </DialogActionsBar>
      </form>
    </Dialog>
  )
}