import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { AudienceOverviewTabs, type AudienceOverviewTabsType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes"
import type { RootState } from "../../../store/reducers/rootReducer";
import { Icon } from "../../shared/Icon/Icon";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { editAudiencesNavigation } from "../../../store/reducers/audiencesNavigationReducer/audiencesNavigationReducer";

type Props = {
  selectedTab: AudienceOverviewTabsType;
  setSelectedTab: (tab: AudienceOverviewTabsType) => void;
}

export const AudienceHeader = ({ selectedTab, setSelectedTab }: Props) => {
  const { displayName, audienceAction, batchIds, sampleInfo } = useSelector((state: RootState) => state.audienceDataReducer);

  const params: { projectId: string, audienceId: string, sampleId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;
  const dispatch = useDispatch();

  return (
    <section className="bg-white">
      <div className="d-flex gap-md justify-content-between p-6 border-bottom">
        <div className="d-flex align-items-center gap-lg w-100">
          <Tooltip anchorElement="target" position="bottom">
            <Link
              title="Back to audiences"
              to={"/audiences"}
              className='d-flex h-32 w-32 justify-content-center align-items-center border cursor-pointer rounded'>
              <Icon className='pe-none' type="arrow-left" />
            </Link>
          </Tooltip>
          <div className="d-flex align-items-center h-32">
            {
              audienceAction === "new" &&
              <h1 className='stronger m-0 text-truncate cursor-default h3' title="Create new audience">
                Create new audience
              </h1>
            }

            {
              audienceAction === "copy" &&
              <h1 className='stronger m-0 text-truncate cursor-default h3' title="Create audience copy">
                Create audience copy
              </h1>
            }

            {
              audienceAction === "edit" &&
              <h1 className='stronger m-0 text-truncate cursor-default h3' title={displayName}>
                <Link to="/audiences" onClick={() => dispatch(editAudiencesNavigation({ projectId: projectId, sampleId: sampleId, audienceId: audienceId, batchId: "" }))}>{sampleInfo?.displayName}</Link> / {displayName}
              </h1>
            }
          </div>
        </div>
      </div>

      {audienceAction === "edit" && (
        <ul className="nav nav-pills pl-4">
          {
            AudienceOverviewTabs.map((tab) => (
              // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
              <li
                key={tab}
                className={`nav-link ${selectedTab === tab && 'active'}`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab} {tab === "Batches" && `(${batchIds.length})`}
              </li>
            ))
          }
        </ul>
      )}
    </section>
  )
}